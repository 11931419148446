import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'

const ProductsSection = ({ products }) => (
  <div className="row mb-2 justify-content-around align-content-center">
    {products.map(({ name, imagesLq, slug, shortDescription }) => (
      <div className="col-md-5 my-4 border product-card p-0 background-white-pure" key={slug}>
        <Link to={`/products/${slug}`}>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <img src={`https:${imagesLq[0].file.url}`} alt={shortDescription} />
            </div>
            <div className="col-md-7 d-flex flex-column p-3">
              <h2 className="font-weight-bold">{name}</h2>
              <p className="pr-2">{shortDescription}</p>
              <div className="d-flex flex-column flex-grow-1 justify-content-end">
                <h5 className="text-purple">View details</h5>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ))}
  </div>
)

export default (props) => {
  const { pageTitle, subTitle, products } = props.data.allContentfulPageProductsModal.nodes[0]

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-5">
          <PageSubtitle subtitle={subTitle} />
          <ProductsSection products={products} />
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllProductsQuery {
    allContentfulPageProductsModal {
      nodes {
        pageTitle
        subTitle
        products {
          shortDescription
          name
          imagesLq {
            file {
              url
            }
          }
          slug
        }
      }
    }
  }
`
